<template>
    <div class="content-slider-collection slider-partial" v-if="data">

        <!-- <VideoOverviewItem v-for="item in data" :key="item.id" :data="item"
                :doCalcOverlayPosition="calcOverlayPosition" /> -->


        <div class="content-grid-collection" v-if="data && contentType" :class="contentType">

            <!-- <div class="items-wrapper" v-if="contentType && contentType.includes('artikelen')">
                <Flickity ref="flickity" :options="flickityOptions">

                    <li :class="contentType.toLowerCase()" v-for="item in data" :key="item.id">
                        <BlogOverviewItem :data="item" />
                    </li>

                </Flickity>
            </div>


            <transition name="slow-fade">
                <div class="items-wrapper" v-if="contentType && contentType.includes('faq')">
                    <ul>
                        <li :class="contentType.toLowerCase()" v-for="item in data" :key="item.id">
                            <FaqItem :data="item" />
                        </li>
                    </ul>
                </div>
            </transition>
            <transition name="slow-fade">
                <div class="items-wrapper" v-if="contentType && contentType.includes('video categories')">
                    <ul>
                        <li :class="'video-categories'" v-for="item in data" :key="item.id">
                            <VideoCategoryOverviewItem :data="item" />
                        </li>
                    </ul>
                </div>
            </transition>
            <transition name="slow-fade">
                <div class="items-wrapper" v-if="contentType && contentType.includes('coaches & trainers')">
                    <ul>
                        <li :class="transformContentType(contentType)" v-for="item in data" :key="item.id">
                            <TeacherOverviewItem :data="item" />
                        </li>
                    </ul>
                </div>
            </transition>
            <transition name="slow-fade">
                <div class="items-wrapper" v-if="contentType && contentType.includes('journeys')">
                    <ul>
                        <li :class="transformContentType(contentType)" v-for="item in data" :key="item.id">
                            <JourneyOverviewItem :data="item" />
                        </li>
                    </ul>
                </div>
            </transition>

            <transition name="slow-fade">
                <div class="items-wrapper component-wrapper" v-if="contentType && contentType.includes('component')">
                    <PlaylistContentColumn v-for="item in data" :key="item.id" :data="item" />
                </div>
            </transition> -->

            <div class="items-wrapper component-wrapper" v-if="contentType && contentType.includes('component')">

                <Flickity ref="flickity" :options="flickityOptions">

                    <div :class="setComponentClasses(item)" v-for="item in data" :key="item.id">
                        <PlaylistContentColumn :data="item" />
                    </div>

                </Flickity>
            </div>
        </div>
    </div>
</template>

<script>
// @import component
// import VideoOverviewItem from "@/components/VideoOverviewItem";
import Flickity from "@/components/Flickity";

import { defineAsyncComponent } from "vue";

// const BlogOverviewItem = defineAsyncComponent(() => import("@/components/BlogOverviewItem"));
// const FaqItem = defineAsyncComponent(() => import("@/components/FaqItem"));
// const TeacherOverviewItem = defineAsyncComponent(() => import("@/components/TeacherOverviewItem"));
// const VideoCategoryOverviewItem = defineAsyncComponent(() => import("@/components/VideoCategoryOverviewItem"));
// const JourneyOverviewItem = defineAsyncComponent(() => import("@/components/JourneyOverviewItem"));

const PlaylistContentColumn = defineAsyncComponent(() => import("@/components/PageLayoutBuilder/Partials/Content/PlaylistContentColumn"));


export default {
    name: "contentSliderBar",
    components: {
        // VideoOverviewItem,
        Flickity,
        // BlogOverviewItem,
        // FaqItem,
        // TeacherOverviewItem,
        // VideoCategoryOverviewItem,
        // JourneyOverviewItem,
        PlaylistContentColumn
    },
    data() {
        return {
            calcOverlayPosition: false,
            flickityOptions: {
                initialIndex: 0,
                cellAlign: "left",
                wrapAround: true,
                pageDots: false,
                groupCells: true,
                arrowShape:
                    "M50.2,98.9l8.9-8.9L25.4,56.2l73.9,0V43.5l-73.9,0L59.1,9.8l-8.9-8.9l-49,49L50.2,98.9z",
            },
        };
    },
    props: ["data", 'contentType'],
    mounted() {
        this.$refs.flickity.on("dragStart", () => {
            //this.$refs.flickity.$flickity.element.style.pointerEvents = "none";
            this.$refs.flickity.$flickity.element.classList.add("dragging");
        });
        this.$refs.flickity.on("dragEnd", () => {
            // this.$refs.flickity.$flickity.element.style.pointerEvents = "auto";
            this.$refs.flickity.$flickity.element.classList.remove("dragging");
        });
        // this.$refs.flickity.on("change", () => {
        //     this.calcOverlayPosition = true;

        //     setTimeout(() => {
        //         this.calcOverlayPosition = false;
        //     }, 100);
        // });
    },
    methods: {
        replaceAll(str, find, replace) {
            return str.replace(new RegExp(find, "g"), replace);
        },
        reformatTitle(title) {
            return title;
        },
        setComponentClasses(item) {
            const regex = /[0-9\s!@#$%^&*()_+\-=[\]{};':"\\|,.<>?/]+/g;

            if (item?.contentName) {
                const classToLower = item.contentName.toLowerCase();
                const adjustedClass = classToLower.replace(regex, '-');


                return adjustedClass;
            } else {
                return '';
            }
        }
    },
};
</script>

<style lang="scss">
// @import styles
@import "./index.scss";
</style>